.header-container {
  display: flex;
  padding: 1.2em;
  justify-content: space-evenly;
  background-color: #c6c9a4;
}

.recipeType-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: em;
  margin-bottom: 1em;
}

.hero-container {
  display: flex;
  text-align: center;
  padding: 2rem;
  justify-content: center;
  background-color: #78b649;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-resize1 {
  display: flex !important;
  justify-content: center;
}

.image-resize2 {
  display: flex !important;
  justify-content: center;
}

.image-resize3 {
  display: flex !important;
  justify-content: center;
}

.image-resize4 {
  display: flex !important;
  justify-content: center;
}

.add-new {
  padding: 100px;
}

.recipe-cards-overall {
  display: flex;
  justify-content: space-between;
  background-color: #6f8f71;
}

.recipe-card {
  width: 300px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.342) 0px 14px 28px,
    rgba(0, 0, 0, 0.237) 0px 10px 10px;
}

.recipes-cards-group {
  display: flex;
  justify-content: center;
  align-items: center;

}

.recipe-images {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.recipe {
  padding: 150px;
  height: 70vh ;
  background-color: rgb(246, 228, 212);
  display: flex;
}

.recipeContainer {
  padding-left: 2em;
  margin-top: -80px;
}

.imageContainer {
  margin-top: -50px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
}

.logo1 {
  width: 20vw;
}


.button-recipe-type {
  align-items: center;
  background-color: #c6c9a4;
  border: 2px solid rgb(44, 44, 44);
  border-radius: 8px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.text-link {
  text-decoration: none;
}

.button-recipe-type:after {
  background-color: rgba(44, 44, 44, 0.697);
  border-radius: 8px;
  content: "";
  display: block;
  height: 36px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button-recipe-type:hover:after {
  transform: translate(0, 0);
}

.button-recipe-type:active {
  background-color: #c6c9a4;
  outline: 0;
}

.button-recipe-type:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-recipe-type {
    padding: 0 40px;
  }
}

.button-header {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: black;
  background: #c6c9a4;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


@keyframes glowing-button-header {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-header:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #909980;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.delete-edit-button {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgba(36, 34, 34, 0.809);
  background: #c6c9a4;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


@keyframes glowing-button-header {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.delete-edit-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(250, 215, 185);
  left: 0;
  top: 0;
  border-radius: 10px;
}

.form-add-recipe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -70px;
  color: #658467;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #6f8f71;
  border-width: 55px;
  flex-direction: column;
  margin-left: 400px;
  margin-right: 400px;
  background-color: rgb(255, 242, 231);

}

.login-title {
  color: #f8a814;
}

.login-button {
  display: flex;
  justify-content: center;
  margin: 8px;
  border: solid #f8a814 0.2em;
  margin-left: 87px;
  margin-right: 87px;
}

.login-email {
  margin: 14px;
}

.login-password {
  margin-top: 20px;
}

.google-login-container {
  display: flex;
  justify-content: center;
  border: solid #f8a814 0.5em;
}

.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #c6c9a4;
  border-width: 55px;
  flex-direction: column;
  margin-left: 400px;
  margin-right: 400px;
  background-color: rgb(255, 242, 231);
}

.signup-title {
  color: #f8a814;
}

.signup-email {
  margin: 14px;
}

.signup-password {
  margin-top: 20px;
}

.signup-button {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 20px;
  border: solid #f8a814 0.2em;
  margin-left: 85px;
  margin-right: 85px;
}

footer { 
  
}

footer p { 
  font-size: 0.9em;
  text-align: center;
  color: #afa8a8;
}

footer p a:link,
footer p a:visited { color: #afa8a8; }